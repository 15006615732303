<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="Object.keys(notification).length != 0" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              style="
                padding: 3px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                width: 100%;
              "
            >
              <p>تفاصيل اشعار نقل كامل . . . Transport Notification</p>
              <p></p>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing m-0"
            >
              <!-- Header: Left Content -->
              <table style="width: 100%">
                <tr>
                  <td>
                    <div>
                      <div class="logo-wrapper">
                        <!-- <logo /> -->
                        <img
                          v-if="notification.branch.company.logo"
                          style="max-width: 170px"
                          :src="companyLogo"
                        />
                        <h5 v-else class="text-primary invoice-logo">
                          <p class="card-text mb-0">
                            {{ notification.branch.company.name }}
                          </p>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="card-text mb-25">
                      {{ notification.branch.name }}
                    </p>
                    <p class="card-text mb-25">
                      {{ notification.branch.address }}
                    </p>
                    <p class="card-text mb-0">
                      {{ notification.branch.phone }}
                    </p>
                  </td>
                  <td>
                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <div
                        class="invoice-date-wrapper"
                        v-if="notification.date != 'null'"
                      >
                        <p class="invoice-date-title">
                          {{ $t("Actual Date") }}:
                        </p>
                        <p class="invoice-date">
                          {{ notification.date | formatDate }}
                        </p>
                      </div>

                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          {{ $t("Date Issued") }}:
                        </p>
                        <p class="invoice-date">
                          {{ notification.created_at | formatDate }}
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          {{ $t("Last Update") }}:
                        </p>
                        <p class="invoice-date">
                          {{ notification.updated_at | formatDate }}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </b-card-body>
          <hr class="invoice-spacing" />
          <b-card-body v-if="notification" class="invoice-padding pt-0">
            <table class="table table-condenced table-hover">
              <tr>
                <th>{{ $t("Notification No") }} :</th>
                <td># {{ notification.serial }}</td>
                <th>
                  {{ $t("client") }}
                </th>
                <td>
                  {{ notification.client ? notification.client.name : "" }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t("container_no") }}
                </th>
                <td>
                  {{ notification.container_no }}
                  {{
                    notification.container_no != null &&
                    notification.container != null
                      ? notification.container.container
                      : notification.container_no_manual
                  }}
                </td>
                <th></th>
                <td></td>
              </tr>

              <tr>
                <th>{{ $t("permission_no") }} :</th>
                <td># {{ notification.permission_no }}</td>
                <th>
                  {{ $t("goods_type") }}
                </th>
                <td>
                  {{
                    notification.goods_type ? notification.goods_type.name : ""
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("process_owner") }} :</th>
                <td># {{ notification.process_owner }}</td>
                <th>
                  {{ $t("qantity") }}
                </th>
                <td>
                  {{ notification.qantity }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("transaction_number") }} :</th>
                <td># {{ notification.transaction_number }}</td>
                <th>
                  {{ $t("source") }}
                </th>
                <td>
                  {{ $t(notification.source) }}
                </td>
              </tr>
            </table>
            <hr />
            <b-row class="invoice-spacing">
              <b-table-lite
                responsive
                striped
                :items="notification.details"
                :fields="[
                  {
                    key: 'notification_type.name',
                    label: $t('notification_type'),
                  },
                  { key: 'car.board_number', label: $t('board_number') },
                  { key: 'driver.name_ar', label: $t('driver') },
                  { key: 'city_from.name', label: $t('from_city') },
                  { key: 'city_to.name', label: $t('to_city') },
                  { key: 'moved_by', label: $t('moved_by') },
                ]"
              >
              </b-table-lite>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <p style="margin: 10px 30px; text-align: center">
            المحتويات المشار إليها أعله تقع على مسؤولية العميل ول تقع على
            مسؤولية الناقل، وعدد الطرود ومحتوياتها المذكورة أعله هي حسب تصريح
            العميل ول تشكل اي مسؤولية علي الناقل The contents referred to above
            is located on the responsibility of the customer and not fall on the
            responsibility of the carrier, And the number of parcels and
            contents mentioned above statement by customer and not
            responsibility on the carrier
          </p>
          <hr class="invoice-spacing" />
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Print -->
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printReceipt"
          >
            <feather-icon icon="PrinterIcon" class="mr-25" />
            <span>{{ $t("Print") }}</span>
          </b-button>

          <b-button
            variant="outline-info"
            class="mb-75"
            v-if="notification.is_goods_arrive == '0'"
            block
            :to="{
              name: 'transport.waybills.tracking',
              params: { id: notification.id },
            }"
          >
            <feather-icon icon="ActivityIcon" class="mr-25" />
            <span>{{ $t("Tracking") }}</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
} from "bootstrap-vue";

export default {
  data() {
    return {
      notification: {},
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },

  computed: {
    companyLogo() {
      var subdomain = window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : false;
      return (
        "http://" +
        subdomain +
        ".optimum.local/" +
        this.notification.branch.company.logo
      );
    },
  },
  created() {
    this.$store.commit("START_LOADING");
    this.$http
      .get(`/crm/notification/${this.$route.params.id}/view`)
      .then((response) => {
        this.notification = response.data.notification;
        this.$store.commit("STOP_LOADING");
      });
  },
  methods: {
    printReceipt() {
      window.print();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.print-sign {
  display: none;
}
@media print {
  // Global Styles
  body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }
  .content-header {
    display: none;
  }
  .attachments {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .print-sign {
    display: block;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .table th,
  .table td {
    padding: 0.7rem !important;
  }
}
.table th,
.table td {
  padding: 0.7rem !important;
}
</style>
